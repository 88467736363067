module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.7.0_tfhgxtdn4xmrar6x46qfuwto54/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.7.0_x2jbhb34jxoy3wtlb42mltwihu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
